.calls-quick-view-card {
  background: #ffffff;
  border-radius: rem(8);
  padding: rem(40) rem(24);
  display: flex;
  gap: rem(24);
  align-items: flex-start;

  .calls-quick-view-card-sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    border-right: 1px solid #d2dada;
    padding-right: rem(24);
  }

  .calls-quick-view-card-sidebar-content {
    display: flex;
    flex-direction: column;
    gap: rem(32);
    align-items: flex-start;
    width: rem(252);
  }

  .call-header {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    width: rem(170);
  }

  .call-id {
    color: #000000;
    font-size: rem(20);
    line-height: rem(30);
    font-weight: 600;
    align-self: stretch;
  }

  .status-badge {
    background: #fcf7fc;
    border-radius: rem(9999);
    border: 1px solid #efddf1;
    padding: 0 rem(8);
    display: flex;
    gap: rem(4);
    align-items: center;
    justify-content: center;
    height: rem(32);
    mix-blend-mode: multiply;
  }

  .badge-text {
    color: #824481;
    text-align: center;
    font-size: rem(16);
    line-height: rem(24);
    font-weight: 500;
  }

  .info-list {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    align-self: stretch;
  }

  .info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
  }

  .info-label {
    display: flex;
    gap: rem(8);
    align-items: center;
  }

  .icon {
    width: rem(24);
    height: rem(24);
  }

  .label-text {
    color: #000000;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 400;
  }

  .value-text {
    color: #000000;
    text-align: right;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 500;
  }

  .divider {
    border-top: 1px solid #d2dada;
    margin-top: rem(-1);
    width: 100%;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: rem(24);
    width: rem(695);
  }

  .takeaways-section {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    align-self: stretch;
  }

  .p-button {
    width: rem(252);
  }
}

.calls-quick-view-modal {
  .p-dialog-header {
    max-height: 0;
    padding: initial;
  }

  .p-dialog-header-icons {
    margin-top: rem(55);
    padding-right: rem(12);
  }
}
