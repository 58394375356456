//===========================================
// -Variables start
//===========================================
@import './mixins';

// Breakpoints
$breakpoints: (
  'xs': 320px,
  'sm': 640px,
  'md': 960px,
  'lg': 1366px,
);

// Fonts
$fonts: (
  'english': 'IBM Plex Sans Arabic',
  'arabic': 'IBM Plex Sans Arabic',
  'icons': 'Font Awesome 6 Pro',
  'pi': 'primeicons',
);

// Font Sizes
$sizes: (
  '1': 44,
  '2': 36,
  '3': 32,
  '4': 28,
  '5': 24,
  '6': 20,
  '7': 18,
  '8': 16,
  '9': 14,
  '10': 12,
  '11': 10,
  '12': 8,
);

// Font Weight
$weights: (
  '400': 400,
  '500': 500,
  '600': 600,
  '700': 700,
);

// Base
$black: #000000;
$white: #ffffff;

// Colors
$purple: (
  '10': #f7eaf4,
  '20': #f8dcf1,
  '30': #f0bded,
  '40': #c679c5,
  '50': #a45da3,
  '60': #824481,
  '70': #561157,
  '80': #37003d,
  '90': #220d22,
);

$teal: (
  '10': #eef8f8,
  '20': #d5ecec,
  '30': #bdefef,
  '40': #9defef,
  '50': #00bfbc,
  '60': #00a5a3,
  '70': #00807d,
  '80': #005e5d,
  '90': #094040,
);

$neutral: (
  '10': #f4f6f6,
  '20': #e3e8e8,
  '30': #d2dada,
  '40': #c7cccc,
  '50': #afb6b6,
  '60': #8d9696,
  '70': #5c7070,
  '80': #313d3d,
  '90': #0d2222,
  '700': #5c7070,
  '900': #0d2222,
);

$green: (
  '10': #ebfff5,
  '20': #dcffde,
  '30': #c2ffe0,
  '40': #99ffcc,
  '50': #45d98f,
  '60': #14a85e,
  '70': #008547,
  '80': #006636,
  '90': #00391a,
);

$yellow: (
  '10': #fff9eb,
  '20': #fff0d1,
  '30': #fce5a9,
  '40': #ffd469,
  '50': #fcc63d,
  '60': #d9a200,
  '70': #996b00,
  '80': #402d00,
  '90': #322300,
  '100': #8d5a5a,
);

$red: (
  '10': #fff0ed,
  '20': #ffdcd6,
  '30': #ffbfb5,
  '40': #ff897a,
  '50': #e44d47,
  '60': #d61f1f,
  '70': #ba1b1b,
  '80': #930006,
  '90': #680003,
);

$blue: (
  '10': #ebf5ff,
  '20': #cfe7ff,
  '30': #a3d1ff,
  '40': #66b3ff,
  '50': #2196f3,
  '60': #007acc,
  '70': #005c99,
  '80': #00325a,
  '90': #002644,
);
$lightapricot: (
  '10': #fff3dc,
  '20': #ffebc6,
  '30': #ffe2b0,
  '40': #ffd999,
  '50': #ffd183,
  '60': #604506,
  '70': #ffc056,
  '80': #ffb740,
  '90': #ffae2a,
  '100': #ffa514,
);

// Text Colors
$textColors: (
  'primary': variableValues($neutral, '80'),
  'secondary': variableValues($neutral, '70'),
  'placeholder': variableValues($neutral, '50'),
  'helper': variableValues($neutral, '60'),
  'on-color': $white,
  'error': variableValues($red, '60'),
  'inverse': $white,
  'on-color-disabled': variableValues($neutral, '50'),
);

// Border Radius
$border-radius: (
  '0': 0,
  '1': 4px,
  '2': 6px,
  '3': 12px,
  '4': 18px,
  '5': 50px,
);

//Widths
$widths: (
  '10': 10%,
  '20': 20%,
  '30': 30%,
  '40': 40%,
  '50': 50%,
  '60': 60%,
  '70': 70%,
  '80': 80%,
  '90': 90%,
  '100': 100%,
);

// define your vars or override bootstrap vars
$primary: variableValues($teal, '70');
$primary-dark: variableValues($teal, '80');
$primary-darker: variableValues($teal, '90');
$primary-text: $white;
$primary-text-alt: $black;
$sidenav-background: variableValues($teal, '90');
$header-height: 64px;

$font-path: '../fonts';

// ===========================================
// -Variables end
// ===========================================
