.new-deals-listing-health-quickview {
  .quickview-content {
    display: flex;
    flex-direction: column;
    gap: rem(10);
  }

  .quickview-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .health-status-label {
    color: rgba(0, 0, 0, 0.7);
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 500;
    width: rem(116);
  }

  .status-indicator {
    background: #ecfdf5;
    border-radius: rem(8);
    padding: rem(8) rem(4);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(32);
    height: rem(32);
  }

  .status-value {
    color: #059669;
    font-size: rem(10);
    line-height: rem(14);
    font-weight: rem(500);
  }

  .section-container {
    background: #fafafa;
    border-radius: rem(4);
    padding: rem(11) rem(12);
    display: flex;
    flex-direction: column;
    gap: rem(10);
    width: 100%;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    gap: rem(12);
  }

  .section-header {
    color: rgba(0, 0, 0, 0.7);
    font-size: rem(12);
    line-height: rem(16);
    font-weight: 500;
    width: 100%;
  }

  .list-container {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    width: 100%;
  }

  .list-item {
    display: flex;
    flex-direction: row;
    gap: rem(7);
    align-items: center;
    width: 100%;
  }

  .status-dot {
    border-radius: 50%;
    width: rem(6);
    height: rem(6);
  }

  .status-dot-success {
    background: #10b981;
  }

  .status-dot-warning {
    background: #e99500;
  }

  .list-item-text {
    color: rgba(0, 0, 0, 0.7);
    font-size: rem(10);
    line-height: rem(14);
    font-weight: 400;
    width: rem(194);
  }

  .action-button {
    background: #dcfaf7;
    border-radius: rem(4);
    padding: 0 rem(8);
    display: flex;
    gap: rem(4);
    align-items: center;
    justify-content: center;
    height: rem(24);
    min-width: rem(64);
    mix-blend-mode: multiply;
  }

  .button-icon {
    width: rem(16);
    height: rem(16);
  }

  .button-text {
    color: #15888a;
    font-size: rem(12);
    line-height: rem(16);
    font-weight: 500;
  }
}

.heatmap-card-overlay {
  .p-overlaypanel-content {
    padding: 0;
  }
}
.heatmap-card {
  width: rem(280);
  height: rem(337);
  position: relative;
  overflow: hidden;

  &__container {
    background: #fafafa;
    border-radius: rem(4);
    width: rem(240);
    height: 8rem;
    position: absolute;
    left: 1.25rem;
    top: 4rem;
  }

  &__title {
    color: rgba(0, 0, 0, 0.7);
    font-family: 'IbmPlexSansArabic-SemiBold', sans-serif;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 600;
    position: absolute;
    left: rem(20);
    top: rem(16);
  }

  &__subtitle {
    @extend .heatmap-card__title;
    top: rem(218);
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    align-items: center;
    width: rem(21);
    position: absolute;
    top: rem(68);

    &-wrapper {
      flex-shrink: 0;
      width: rem(21);
      height: rem(96);
      position: static;
    }

    &-bg {
      background: #e5e5e5;
      border-radius: rem(4);
      width: rem(21);
      height: rem(96);
      position: absolute;
      left: 0;
      top: 0;
    }

    &-call {
      background: #a257a2;
      border-radius: rem(4) rem(4) 0 0;
      width: rem(21);
      position: absolute;
      left: 0;
    }

    &-chat {
      background: #1aaba9;
      border-radius: 0 0 rem(4) rem(4);
      width: rem(21);
      position: absolute;
      left: 0;
    }

    &-day {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-family: 'IbmPlexSansArabic-Medium', sans-serif;
      font-size: rem(10);
      line-height: rem(14);
      font-weight: 500;
      position: relative;
      align-self: stretch;
    }
  }

  &__stats-box {
    border-radius: rem(4);
    padding: rem(12);
    width: rem(116);
    height: rem(75);
    position: absolute;
    left: rem(20);
    top: rem(246);

    &--call {
      background: #fcf7fc;
    }

    &--chat {
      background: #edfffc;
      left: rem(144);
    }

    &-title {
      color: rgba(0, 0, 0, 0.7);
      font-family: 'IbmPlexSansArabic-SemiBold', sans-serif;
      font-size: rem(16);
      line-height: rem(24);
      font-weight: 600;
      position: absolute;
      left: rem(12);
      top: rem(12);
      width: rem(92);
    }

    &-subtitle {
      color: rgba(0, 0, 0, 0.7);
      font-family: 'IbmPlexSansArabic-Regular', sans-serif;
      font-size: rem(10);
      line-height: rem(14);
      font-weight: 400;
      position: absolute;
      left: rem(24);
      top: rem(36);
      width: rem(80);
    }

    &-indicator {
      border-radius: 50%;
      width: rem(8);
      height: rem(8);
      position: absolute;
      left: rem(12);
      top: rem(39);

      &--call {
        background: #a257a2;
      }

      &--chat {
        background: #15888a;
      }
    }
  }
}
