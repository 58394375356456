@import '../../scss/helpers/variables';
@import '../../scss/helpers/mixins';

.with-side-filter-drawer {
  .side-filter-clear-button-main {
    padding: rem(10.6) rem(21);
  }
}

.side-filter-drawer {
  width: 25rem;

  .p-sidebar-header {
    padding-bottom: 0;
  }

  .p-accordion .p-accordiontab {
    margin-bottom: rem(16) !important;
  }

  .p-accordion .p-accordiontab:last-child {
    margin-bottom: 0 !important;
  }

  .p-selectbutton {
    div.p-element.p-component {
      margin-bottom: rem(5);
    }
  }
}

.side-filter-list-p-badge-contrast {
  color: $primary-text;
  background-color: $primary;
}

.side-filter-drawer-date-range {
  display: contents;

  span.p-calendar {
    display: contents;
  }
}

.side-filter-drawer-invalid-time-range {
  input {
    border: rem(2) solid variableValues($red, '50');
  }
}
